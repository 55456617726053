// import ApiBridgeRegisterFormPage from "../Pages/ApiBridgeRegisterFormPage"
// import RootPage from "../Pages/RootPage"
// import UsagePolicy from "../Pages/UsagePolicy";
// import PrivacyPolicy from "../Pages/PrivacyPolicy";
// import RefundPolicy from "../Pages/RefundPolicy";
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const adminComponent = () => import("../Pages/AdminPage.vue")
const routes = [
  {path:"/" , component :() => import("../Pages/RootPage") , name:"RootRoute"},
  {path:"/admin" , component : adminComponent, name:"AdminRootPage"},
  {path:"/angel" , component : () => import("../Pages/AngelRegistration"), name:"AngelRootPage"},
  // {path:"/angelb" , component : () => import("../Pages/AngelRegistration"), name:"AngelAuthPage" },

  {path:"/usage-policy" , component : () => import("../Pages/UsagePolicy") , name:"usagePolicyRoute"},
  {path:"/refund-policy" , component : () => import("../Pages/RefundPolicy") , name:"refundPolicyRoute"},
  {path:"/privacy-policy" , component : () => import("../Pages/PrivacyPolicy") , name:"privacyPolicyRoute"},
  {path:"/mycoder" , component : () => import("../Pages/meetupForm/MeetUpFormPage"), name:"meetupRegisterForm"},
  {path:"/:formName" , component : () => import("../Pages/ApiBridgeRegisterFormPage")  , name:"registerRoute"},

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
